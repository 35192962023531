import * as React from "react";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import Grid2 from "@mui/material/Unstable_Grid2/Grid2.js";
import { Typography } from "@mui/material";

export default function QRCodeTitleCard({ title }) {
  return (
    <Card sx={{ padding: "2rem", marginTop:'1rem' }}>
      <CardContent>
        <Grid2 container>
          <Grid2 xs={12}>
            <Typography variant="h1">{title}</Typography>
          </Grid2>
        </Grid2>
      </CardContent>
    </Card>
  );
}
