import React from "react";
import { MuiColorInput } from "mui-color-input";
import { Typography } from "@mui/material";
import { Container } from "@mui/material";
import { FormLabel } from "@mui/material";
import BasicTooltip from "./tooltip";

export default function ColorPicker({ handleColor, label, toolTipText }) {
  const [value, setValue] = React.useState("rgb(0,0,0)");

  const handleChange = (newValue) => {
    setValue(newValue);
    handleColor(newValue);
  };

  return (
    <Container style={{ paddingLeft: "0" }}>
      <Typography>
        <FormLabel id="demo-row-radio-buttons-group-label">{label}</FormLabel>
        <BasicTooltip toolTip={toolTipText}></BasicTooltip>
      </Typography>
      <MuiColorInput format="rgb" value={value} onChange={handleChange} />
    </Container>
  );
}
