import * as React from "react";
import Card from "@mui/material/Card";
import CardActions from "@mui/material/CardActions";
import CardContent from "@mui/material/CardContent";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faQrcode, faBug } from "@fortawesome/free-solid-svg-icons";
import Grid2 from "@mui/material/Unstable_Grid2/Grid2";
import CircularIndeterminate from "./loading";

export default function QRPreview({ imageUrl, loadingState, errorState }) {
  function QRCodeComponent({ imageUrl, loadingState }) {
    // show qr code fa icon on startup
    if (!errorState && !loadingState && !imageUrl) {
      return (
        <FontAwesomeIcon
          icon={faQrcode}
          style={{ width: "60%", height: "60%" }}
        />
      );
    } else if (errorState) {
      return (
        <>
          <FontAwesomeIcon
            icon={faBug}
            color="red"
            style={{ width: "60%", height: "60%" }}
          />
          <Typography>
            There was a server error, please try{" "}
            <a href="https://www.qr-gen.link">again</a>.
          </Typography>
        </>
      );
    } else if (loadingState) {
      return <CircularIndeterminate></CircularIndeterminate>;
      // if there is an image and not loading show the image
    } else if (imageUrl !== "") {
      return (
        <>
          <img style={{ width: "100%" }} src={imageUrl} alt="QR Code" />
          <Button
            style={{ width: "100%", marginTop: "2rem" }}
            variant="contained"
            size="medium"
            color="primary"
            href={imageUrl}
            target="_blank"
          >
            Download
          </Button>
        </>
      );
    }
  }
  return (
    <Card style={{ height: "100%" }}>
      <CardContent style={{ height: "100%" }}>
        <Grid2
          container
          xs={12}
          justifyContent="center"
          alignItems="center"
          padding={"1rem"}
          height={"100%"}
        >
          <QRCodeComponent
            imageUrl={imageUrl}
            loadingState={loadingState}
          ></QRCodeComponent>
        </Grid2>
      </CardContent>
    </Card>
  );
}

// if (!img_src) {
//   if (loadingState === "loading") {
//     // Display loading icon (or any other loading indicator)
//     return <div>Loading...</div>;
//   } else if (loadingState === "error") {
//     // Display error icon
//     return (
//       <>
//         <FontAwesomeIcon
//           icon={faBug}
//           color="red"
//           style={{ width: "60%", height: "60%" }}
//         />
//         <Grid2>
//           <Typography>There was a server error.</Typography>
//           <Typography>
//             Please try again.
//             {<Button href="https://www.qr-gen.link">Reload</Button>}
//           </Typography>
//         </Grid2>
//       </>
//     );
//   }
//   // Default case (e.g., initial state)
//   return (
//     <FontAwesomeIcon
//       icon={faQrcode}
//       style={{ width: "60%", height: "60%" }}
//     />
//   );
// }

// // img_src is available - Display the image and download button
// return (
//   <>
//     {/* <img style={{ width: "100%" }} src={img_src} alt="QR Code" /> */}
//     <CircularIndeterminate></CircularIndeterminate>
//   </>
// );
// }
// return (
// <Card style={{ height: "100%" }}>
//   <CardContent style={{ height: "100%" }}>
//     <Grid2
//       container
//       xs={12}
//       justifyContent="center"
//       alignItems="center"
//       padding={"1rem"}
//       height={"100%"}
//     >
//       <QRCodeComponent loadingState={loadingState} img_src={img_src} />
//       {loadingState === false && img_src !== "" ? (
//         <Button
//           style={{ width: "100%", marginTop: "2rem" }}
//           variant="contained"
//           size="medium"
//           color="primary"
//           href={img_src}
//           target="_blank"
//         >
//           Download
//         </Button>
//       ) : null}
//     </Grid2>
//   </CardContent>
// </Card>
