import * as React from "react";
import QRCodeConfig from "../components/qr_config.jsx";
import QRPreview from "../components/qr_preview.jsx";
import { Container, Typography } from "@mui/material";
import Grid2 from "@mui/material/Unstable_Grid2/Grid2.js";
import { useState, useEffect } from "react";
import QRCodeTitleCard from "../components/title_card.jsx";
import SimpleSnackbar from "../components/snack_bar.jsx";

export default function Homepage() {
  const [QRData, setQRData] = React.useState({});
  const [QRImgSrc, setQRImgSrc] = React.useState("");
  const [loadingState, setLoadingState] = React.useState(false);
  const [errorState, setErrorState] = React.useState(false);

  const genQRCode = (body) => {
    setLoadingState(true);
    setErrorState(false);

    const requestOptions = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(body),
    };

    fetch(
      "https://r8kf1bony3.execute-api.us-east-1.amazonaws.com/api/generate",
      requestOptions
    )
      .then((response) => {
        if (!response.ok) {
          // If response is not OK, throw an error
          throw new Error(`HTTP error! status: ${response.status}`);
        }
        return response.json();
      })
      .then((data) => {
        setQRImgSrc(data.imgUrl);
        setLoadingState(false); // Reset loading state on success
      })
      .catch((error) => {
        console.error("Fetch error:", error.message);
        setErrorState(true); // set error state
        setQRImgSrc("");
      });
  };

  useEffect(() => {
    if (Object.keys(QRData).length !== 0) {
      genQRCode(QRData);
    }
  }, [QRData]);

  return (
    <Container maxWidth={"xl"}>
      {/* <SimpleSnackbar show={showHistory} history={QRHistory}></SimpleSnackbar> */}
      <Grid2 container spacing={2}>
        <Grid2 xs={12}>
          <QRCodeTitleCard title="QR-Gen.link" />
        </Grid2>
        <Grid2 xs={12} md={8}>
          <QRCodeConfig
            errorState={errorState}
            setQRData={setQRData}
            QRData={QRData}
          ></QRCodeConfig>
        </Grid2>
        <Grid2 xs={12} md={4}>
          <QRPreview
            errorState={errorState}
            loadingState={loadingState}
            imageUrl={QRImgSrc}
          />
        </Grid2>
      </Grid2>
    </Container>
  );
}
