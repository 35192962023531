import * as React from "react";
import ToggleButton from "@mui/material/ToggleButton";
import ToggleButtonGroup from "@mui/material/ToggleButtonGroup";
import { Typography, FormLabel } from "@mui/material";
import BasicTooltip from "./tooltip";
import { mainTheme } from "./theme";
import { styled } from "@mui/system";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSquare } from "@fortawesome/free-solid-svg-icons";

const StyledToggleButton = styled(ToggleButton)(({ theme }) => ({
  "&.Mui-selected": {
    color: "white", // Selected state color
    backgroundColor: theme.palette.primary.light, // Selected state background color
    "&:hover": {
      backgroundColor: theme.palette.primary.dark, // Selected state hover color
    },
  },
}));


export default function ToggleButtons({
  label,
  options,
  handleSelection,
  toolTipText,
  orientation,
}) {
  const [selected, setSelected] = React.useState(
    options && options.length > 0 ? options[0] : ""
  );

  const handleSelected = (event, newSelected) => {
    if (newSelected !== null) {
      setSelected(newSelected);
      handleSelection(newSelected);
    }
  };

  React.useEffect(() => {
    if (options && options.length > 0 && !options.includes(selected)) {
      setSelected(options[0]);
    }
  }, [options, selected]);

  return (
    <>
      <Typography>
        <FormLabel id="demo-row-radio-buttons-group-label">{label}</FormLabel>
        <BasicTooltip toolTip={toolTipText}></BasicTooltip>
      </Typography>

      <ToggleButtonGroup
      sx={{flexWrap:'wrap'}}
        value={selected}
        exclusive
        onChange={handleSelected}
        aria-label="text alignment"
      >
        {Array.isArray(options) &&
          options.map((option, index) => (
            <StyledToggleButton key={index} value={option} aria-label={option}>
              {option}
              {/* <FontAwesomeIcon icon={faSquare} /> */}
            </StyledToggleButton>
          ))}
      </ToggleButtonGroup>
    </>
  );
}
