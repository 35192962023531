import "./App.css";
import React from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import { ThemeProvider } from "@mui/material";
import Homepage from "./views/homepage";
import { CssBaseline } from "@mui/material";
import { mainTheme } from "./components/theme";

function App() {
  return (
    <ThemeProvider theme={mainTheme}>
      <CssBaseline />
      <Router>
        <Routes>
          <Route path="/" element={<Homepage></Homepage>}></Route>
        </Routes>
      </Router>
    </ThemeProvider>
  );
}

export default App;
