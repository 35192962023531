import * as React from "react";
import IconButton from "@mui/material/IconButton";
import Tooltip from "@mui/material/Tooltip";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCircleInfo } from "@fortawesome/free-solid-svg-icons";
import { styled } from "@mui/system";
import { mainTheme } from "./theme";
import { Typography } from "@mui/material";

const StyledTypography = styled(Typography)({
  fontSize: "1rem",
});

export default function BasicTooltip({ toolTip }) {
  return (
    <Tooltip
      arrow
      placement="right"
      style={{ marginLeft: "1rem" }}
      title={<StyledTypography>{toolTip}</StyledTypography>}
      >
      <IconButton>
        <FontAwesomeIcon size="xs" icon={faCircleInfo} />
      </IconButton>
    </Tooltip>
  );
}
