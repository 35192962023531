import * as React from "react";
import Box from "@mui/material/Box";
import { TextField, Typography } from "@mui/material";
import BasicTooltip from "./tooltip";
export default function QRDataField({ handleTextChange }) {
  return (
    <Box
      component="form"
      sx={{
        "& > :not(style)": { width: "100%" },
      }}
      noValidate
      autoComplete="off"
    >
      <Typography>
        Destination
        <BasicTooltip toolTip={"Enter a URL or some text to generate a QR code that will link to the destination."}></BasicTooltip>
      </Typography>
      <TextField
        onChange={handleTextChange}
        id="outlined-basic"
        name="qrText"
        placeholder="www.qr-gen.link"
        // label="Destination (URL or Text)"
        variant="outlined"
      />
    </Box>
  );
}
