import { createTheme } from "@mui/material";

export const mainTheme = createTheme({
  palette: {
    primary: {
      main: "#8f1ef8",
      alt: "#ff0097", //#8f1ef8, #ff0097, #ff3731, #fea800, #a8eb12
      light: "#c251ff",
      dark: "#7218c6",
    },
    mode: "dark",
  },
  overrides: {
    MuiTooltip: {
      tooltip: {
        fontSize: "5rem",
      },
    },
  },
});
