import * as React from "react";
import Card from "@mui/material/Card";
import CardActions from "@mui/material/CardActions";
import CardContent from "@mui/material/CardContent";

import Grid2 from "@mui/material/Unstable_Grid2/Grid2.js";

import QRDataField from "./text_input";
import ColorPicker from "./color_picker.jsx";
import { Button } from "@mui/material";
import ToggleButtons from "./btn_group.jsx";
import { useEffect, useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlay } from "@fortawesome/free-solid-svg-icons";

export default function QRCodeConfig({ setQRData }) {
  const [showAlert, setShowAlert] = React.useState(false);
  const [alertMessage, setAlertMessage] = React.useState("");
  const [alertType, setAlertType] = React.useState("warning");

  const submitQRData = () => {
    if ("qrText" in data) {
      // setAlertType("success");
      // setAlertMessage("Generating QR Code");
      setShowAlert(true);
      setQRData(data);
    } else {
      setAlertMessage("No destination has been selected.");
      setAlertType("warning");
      setShowAlert(true);
    }
  };

  const [data, setData] = React.useState({
    qrVersion: 1,
    qrErrCorrect: "Low",
    qrLocatorStyle: "Square",
    qrLocatorColor: "rgb(0,0,0)",
    qrDotStyle: "Square",
    qrDotColor: "rgb(0,0,0)",
  });

  // useEffect(() => {
  //   console.log(data);
  // }, [data]);

  const colorCutoff = 10;
  useEffect(() => {
    if (data.qrVersion >= colorCutoff) {
      setData((prevData) => ({
        ...prevData,
        qrLocatorColor: "rgb(0,0,0)",
        qrDotColor: "rgb(0,0,0)",
      }));
    }
  }, [data.qrVersion]);

  const handleDestination = (event) => {
    setData((prevData) => ({
      ...prevData,
      [event.target.name]: event.target.value,
    }));
  };

  const handleLocatorColor = (qrLocatorColor) => {
    setData((prevData) => ({ ...prevData, qrLocatorColor }));
  };

  const handleDotColor = (qrDotColor) => {
    setData((prevData) => ({ ...prevData, qrDotColor }));
  };

  const handleVersion = (qrVersion) => {
    setData((prevData) => ({ ...prevData, qrVersion }));
  };

  const handleErrCorrect = (qrErrCorrect) => {
    setData((prevData) => ({ ...prevData, qrErrCorrect }));
  };

  const handleLocatorStyle = (qrLocatorStyle) => {
    setData((prevData) => ({ ...prevData, qrLocatorStyle }));
  };

  const handleDotStyle = (qrDotStyle) => {
    setData((prevData) => ({ ...prevData, qrDotStyle }));
  };

  return (
    <Card sx={{ height: "100%", padding: "2rem" }}>
      <CardContent>
        <Grid2 style={{ minWidth: "100%" }} container>
          <Grid2 xs={12}>
            <QRDataField handleTextChange={handleDestination}></QRDataField>
          </Grid2>

          <Grid2
            container
            xs={12}
            justifyContent="center" // Center horizontally
            alignItems="center" // Center vertically
          ></Grid2>

          <Grid2 xs={12}>
            <Button
              type="button"
              size="large"
              onClick={submitQRData}
              variant="contained"
              style={{ height: "75%", width:'100%', marginTop:'1rem' }}
            >
              Generate <FontAwesomeIcon style={{marginLeft:'1rem'}} icon={faPlay} />
            </Button>
          </Grid2>

          <Grid2 xs={12}>
            <ToggleButtons
              handleSelection={handleVersion}
              label={"Generator Version"}
              options={[1, 5, 10, 15, 20, 25, 30, 35, 40]}
              toolTipText={
                "Select the size and complexity of the QR Code. Higher versions have greater capacity for data but are physically larger. Choose based on the amount of information you need to encode."
              }
            ></ToggleButtons>
          </Grid2>

          <Grid2 xs={12}>
            <ToggleButtons
              handleSelection={handleErrCorrect}
              label={"Error Correction"}
              options={["Low", "Medium", "High", "Highest"]}
              toolTipText={
                "Enhance your QR Code's readability in adverse conditions. Higher levels of error correction allow the code to be scanned even if it's partially obscured or damaged. Ideal for ensuring reliability in various environments."
              }
            ></ToggleButtons>
          </Grid2>

          <Grid2 xs={12}>
            <ToggleButtons
              handleSelection={handleLocatorStyle}
              label={"Locator Style"}
              options={["Classic", "Rounded"]}
              toolTipText={
                "Customize the appearance of the three large squares at the corners of the QR Code. Choose between 'Classic' for traditional square corners or 'Rounded' for a softer, curved look. This can help align your QR Code with your brand's aesthetic"
              }
            ></ToggleButtons>
          </Grid2>

          <Grid2 xs={12}>
            <ToggleButtons
              handleSelection={handleDotStyle}
              label={"Dot Style"}
              options={[
                "Classic",
                "Gapped",
                "Rounded",
                "Circles",
                "Verticle",
                "Horizontal",
              ]}
              toolTipText={
                "Customize the appearance of the three large squares at the corners of the QR Code. Choose between 'Classic' for traditional square corners or 'Rounded' for a softer, curved look. This can help align your QR Code with your brand's aesthetic"
              }
            ></ToggleButtons>
          </Grid2>

          <Grid2 xs={12}>
            {data.qrVersion < colorCutoff && (
              <Grid2 container spacing={2}>
                <Grid2 xs={6}>
                  <ColorPicker
                    handleColor={handleLocatorColor}
                    label={"Locator Color"}
                    toolTipText={
                      "Change the color of the QR Code's corner locators to align with your branding or design preference. Note that high contrast colors work best for scan-ability."
                    }
                  />
                </Grid2>
                <Grid2 xs={6}>
                  <ColorPicker
                    handleColor={handleDotColor}
                    label={"Dots Color"}
                    toolTipText={
                      "Customize the color of the dots in your QR Code. Select a color that complements your design while maintaining good contrast with the background for optimal scanning."
                    }
                  />
                </Grid2>
              </Grid2>
            )}
          </Grid2>
        </Grid2>
      </CardContent>
    </Card>
  );
}
